.title {
    font-family: 'PT_Sans', serif;
    font-weight: 700;
    font-size: xxx-large;
    color: hsl(214, 50%, 85%);
    text-align: center;
}
.subtitle {
    font-family: 'PT_Sans', serif;
    font-size: xx-large;
    text-align: center;
    color: hsl(214, 55%, 70%);
}

.blurb {
    color: hsl(214, 41%, 80%);
    margin-top: 5em;
    text-align: center;
    font-size: 2em;
}
@media only screen and  (width <= 768px) {
    .title {
        font-size: xx-large;
    }
    .subtitle {
        font-size: x-large;
    }
    .blurb {
        margin-top: unset;
        font-size: large;
    }
    .home {
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
    }
}