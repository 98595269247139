.sectionHeader {
    font-family: 'PT_Sans', serif;
    font-size: x-large;
    text-align: center;
    color: hsl(214, 35%, 70%);
    margin-bottom: 0.2em;
}
.linkSection {
    padding: 1%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
}
.text {
    color: hsl(214, 45%, 85%);
    font-size: 1em;
    margin: 0.5em;
    word-spacing: 0.1em;
    line-height: 1.2;
    font-family: 'Mulish', sans-serif;
}
.link {
    font-size: 1em;
    margin: 0.5em;
    word-spacing: 0.1em;
    line-height: 1.2;
    font-family: 'Mulish', sans-serif;
    color: hsl(215, 89%, 61%);
    text-decoration: underline;
}
.currentButton {
    background-color: hsl(205, 15%, 58%);
}
.otherButton {
    background-color: hsl(205, 25%, 78%);
}
.folioContainer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;
    height: 60%;
    width: 100%;
}
.buttonSection {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly
}
@media only screen and (width <= 768px) {
    .folioContainer {
        height: auto;
    }
}
