.subHeader {
    color: hsl(214, 45%, 85%);
    font-size: 1.2em;
    font-family: 'Mulish', sans-serif;
    text-align: center;
}

.container {
    width: 70%;
    height: 75%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;
}

.heading {
    font-family: 'PT_Sans', serif;
    font-size: xxx-large;
    text-align: center;
    color: hsl(214, 55%, 70%);
    margin-bottom: 0.2em;
}


.text {
    color: hsl(214, 45%, 85%);
    font-size: 1em;
    margin: 0.5em;
    word-spacing: 0.1em;
    line-height: 1.2;
    font-family: 'Mulish', sans-serif;
}

@media only screen and (width <= 768px) {
    .container {
        width: 90%;
        height: 100%;
        overflow: scroll;
        padding: 3% 3%;
    }
    .heading {
        margin-bottom: 0;
    }
    .headerContainer {
        display: block;
    }
}