.text {
    color: hsl(214, 45%, 85%);
    font-size: 1.3em;
    margin: 0.5em;
    word-spacing: 0.1em;
    line-height: 1.2;
    font-family: 'Mulish', sans-serif;
}

.heading {
    font-family: 'PT_Sans', serif;
    font-size: xx-large;
    text-align: center;
    align-self: center;
    color: hsl(214, 55%, 70%);
}

.slanted {
    color: white;
    font-style: italic;
    margin: 0.1em;
}

.textContainer {
    width: 50%;
    height: 75%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    text-align: left;
    justify-items: center;
    justify-self: flex-start;
}
@media only screen and (width <= 768px) {
    .textContainer {
        width: 85%;
        overflow: scroll;
        height: 100%;
        padding: 1% 1%;
    }
    .text {
        font-size: large;
    }
    .heading {
        font-size: x-large;
    }
}