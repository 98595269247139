.pageContainer {
    height: 100%;
    width: 100%;
    background-image: url('../Assets/Images/AdectuIcon.png');
    background-color: hsl(200, 3%, 23%);
    background-size: 70em;
    background-repeat:repeat;
    background-attachment: scroll;
    background-position: left center;
    display: flex;
    flex-flow: column wrap;
}
.contentContainer {
    height: 100%;
    width: 100%;
    padding: 5% 0% 5% 10%;
    box-sizing: border-box;
    align-self: flex-end;
    align-items:flex-start;
    display: flex;
    justify-content: center;
    justify-items: center;
    background-color: hsla(220, 45%, 8%, 92%);
    border-bottom-left-radius: 90%;
    overflow-y:scroll;
}
.stylingContainer {
    height: 100%;
    width: 70%;
    display: flex;
    flex-flow: column wrap;
    box-sizing: border-box;
    align-self: flex-end;
    display: flex;
    justify-content: center;
    justify-items: center;
    background-image: url('../Assets/Images/codebackground.png');
    background-position: 50%;
    background-size: cover;
    opacity: 0.99;
    border-bottom-left-radius: 50%;
}
@media only screen and (height <= 640) {
        .pageContainer {
        height: 100%;
        background-position-x: center;
        background-position-y: 5em;
        background-size: 100%;
    }
    .stylingContainer {
        height: 80%;
        width: 100%;
        border-bottom-left-radius: unset;
        background-repeat: repeat-y;
        background-position-x: 50%;
        background-position-y: 75%;
    }
    .contentContainer {
        height: 80%;
        justify-self: flex-end;
        border-bottom-left-radius: 0;
        padding: 1% 1%;
    }
}
@media only screen and (width <= 768px) {
    .pageContainer {
        height: 100vh;
        background-position-x: center;
        background-position-y: 5em;
        background-size: 100%;
    }
    .stylingContainer {
        height: 80%;
        width: 100%;
        border-bottom-left-radius: unset;
        background-repeat: repeat-y;
        background-position-x: 50%;
        background-position-y: 75%;
    }
    .contentContainer {
        height: 80%;
        justify-self: flex-end;
        border-bottom-left-radius: 0;
        padding: 1% 1%;
    }
}