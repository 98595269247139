.current {
    background-color: hsla(220, 45%, 80%, 92%);
    text-decoration: none;
    color: black;
    padding: 0.4em;
    border-radius: 0.75em;
}
.other {
    background-color: hsla(220, 45%, 8%, 50%);
    color: white;
    text-decoration: none;
    padding: 0.4em;
    border-radius: 0.75em;
}

.navBar {
    background-color: hsla(220, 45%, 8%, 95%);
    padding: 1%;
    border-width: 0.1em;
    border-style: solid;
    width: 25%;
    display: flex;
    flex-flow: row;
    justify-content:space-evenly;
    align-content: center;
    position: fixed;
    left: 0;
    top: 0;
    border-end-end-radius: 4em;
    border-right-width: 0;
    border-top-width: 0;
    z-index: 1;
}

.divider {
    color: black;
    font-weight: 700;
    font-size: large;
}

@media only screen and (width <= 768px) {
    .navBar {
        width: 15em;
        position: absolute;
        top: 0;
        right: 0;
        left: unset;
        padding: 2% 2%;
        border-end-end-radius: unset;
        font-size: 0.9em;
    }
}