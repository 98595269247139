.contactInfo {
    font-family: 'Mulish', sans-serif;
    font-size: 1.5em;
    color: hsl(214, 45%, 85%);
    margin: 1em;
}

.heading {
    font-family: 'PT_Sans', serif;
    font-size: xx-large;
    text-align: center;
    align-self: center;
    color: hsl(214, 55%, 75%);
    margin-bottom: 1.5em;
}

.details {
    font-family: 'Mulish', sans-serif;
    font-size: 1em;
    color: hsl(214, 45%, 85%);
    margin-bottom: 0.7em;
}
.container {
    margin-top: 1em;
}
@media only screen and (width <= 768px) {
    .container {
        width: 90%;
        height: 95%;
        margin-top: unset;
        padding: 2% 2%;
    }
    .heading {
        font-size: x-large;
    }
    .contactInfo {
        font-size: larger;
    }
    .details {
        font-size: medium;
    }
}